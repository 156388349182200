import React from "react"
import Testimonial from "./testimonials"

export default function TestimonialCocoa2() {
  return (
    <Testimonial
      quote="An amazing guide, I learned so much"
      name="Sven"
      topic="About the Cocoa Trails tour"
    />
  )
}
