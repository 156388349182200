import React from "react"
import Container from "react-bootstrap/Container"
import ThreeCardsShadow from "../threeCardsShadow"
import { GiMeal, GiHotMeal } from "react-icons/gi"
import { MdVilla } from "react-icons/md"

export default function IncludedFood() {
  return (
    <Container>
      <ThreeCardsShadow
        title="Fresh Breakfast Every Day"
        icon={<GiMeal />}
        title2="Hotel Stays for the Entire Trip"
        icon2={<MdVilla />}
        title3="2 Typical Costa Rican Meals"
        icon3={<GiHotMeal />}
      />
    </Container>
  )
}
