import React from "react"
import Container from "react-bootstrap/Container"
import ThreeCardsShadow from "../threeCardsShadow"
import { GiVolcano, GiJungle } from "react-icons/gi"

import { MdSurfing } from "react-icons/md"

export default function IncludedNature() {
  return (
    <Container>
      <ThreeCardsShadow
        title="2 Seas - Carribean and Pacific"
        icon={<MdSurfing />}
        title2="Volcanos"
        icon2={<GiVolcano />}
        title3="Jungle"
        icon3={<GiJungle />}
      />
    </Container>
  )
}
