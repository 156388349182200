import React from "react"
import Container from "react-bootstrap/Container"
import ThreeCardsShadow from "../threeCardsShadow"
import { MdEmojiTransportation, MdFlight } from "react-icons/md"
import { RiFootprintFill } from "react-icons/ri"

export default function IncludedTransfer() {
  return (
    <Container>
      <ThreeCardsShadow
        title="All Transfers between Hotels"
        icon={<MdEmojiTransportation />}
        title2="Preparatory Course in Germany"
        icon2={<RiFootprintFill />}
        title3="Pickup from and Return to Airport"
        icon3={<MdFlight />}
      />
    </Container>
  )
}
