import React from "react"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import Layout from "../components/layout"
import ImgTextSmall from "../components/imgTextSmall"
import TextImg from "../components/textImg"
import DetailsPrice from "../components/tourDetails/daysPrice"

import IncludedFood from "../components/included/food"
import IncludedGuide from "../components/included/guide"
import IncludedDance from "../components/included/dance"
import IncludedTransfer from "../components/included/transfer"
import IncludedNature from "../components/included/nature"

import AboutPageText from "../components/about/aboutPageIndex"
import Cta from "../components/cta"

import TestimonialCocoa2 from "../components/testimonials/cocoaTrail2"
import Seo from "../components/Seo/seo"
import TourJson from "../components/Seo/tourJson"
export default function About({ data }) {
  return (
    <Layout>
      <Seo
        title="Tour Details"
        description="Make a journey of a lifetime in Costa Rica - with hours of dance classes and a lot more included. Find out here."
        image={data.hotel}
      />
      <TourJson />
      <h1 className="display-1 ext-center my-5 lstitle text-uppercase">
        Tour Details
      </h1>
      <h2 className="fw-lighter display-5 text-center mb-5">
        Find out what is included included in the tour and mark the date when
        the next tour in Costa Rica will take place. Find the tour details here
      </h2>
      <ImgTextSmall
        title="STAY at beautiful Hotels"
        text="And relax from your troubles, take a dive in the pool and enjoy living."
        link=""
        btn=""
        img={data.hotel}
        imgAlt="Hotel with pool in Costa Rica"
      />

      <DetailsPrice />
      <div className="mt-5 mb-5"></div>
      <Cta
        title="Make your reservation now"
        subtitle=""
        link="/booking"
        btn="Book here"
        bgcolor="LightCoral"
      />
      <div className="mt-5 mb-5"></div>
      <TextImg
        title="MAKE Memories"
        text="With so many different things to do and see, you will be making cherishable memories!"
        link=""
        btn=""
        img={data.party2}
        imgAlt="Party in Costa Rica"
      />
      <IncludedFood />
      <IncludedGuide />
      <IncludedDance />
      <IncludedTransfer />
      <IncludedNature />

      <Cta
        title="Questions?"
        subtitle="We are happy to help!"
        link="/contact"
        btn="Find answeres here"
        bgcolor="gold"
      />

      <Container className="mt-4">
        <div className="row">
          <div className="col-sm-4 offset-sm-4">
            <h2>About LatinSalsa Tours</h2>
          </div>
        </div>
      </Container>

      <AboutPageText />
      <div className="mt-5 mb-5"></div>
      <TestimonialCocoa2 />
      <div className="mt-5 mb-5"></div>
      <Cta
        title="Allow yourself to make journey of a lifetime!"
        subtitle="Make memories you will cherish and never want to miss."
        link="/booking"
        btn="Travel to Costa Rica"
        bgcolor="LightCoral"
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    hotel: file(name: { eq: "hotel-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    party2: file(name: { eq: "party-costa-rica2" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
