import React from "react"
import JsonLd from "./jsonld"
import { useStaticQuery, graphql } from "gatsby"

export default function TourJson() {
  const { site } = useStaticQuery(query)
  const { duration, price, description, priceValid, legalName, siteUrl, lsDe } =
    site.siteMetadata
  return (
    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": ["Trip", "TouristTrip"],
        name: `${duration} Tour in Costa Rica`,
        description: `${description}`,
        touristType: [
          "Nightlife tourism",
          "Dance tourism",
          "Nature tourism",
          "Adventure tourism",
          "Salsa tourism",
          "Bachata tourism",
          "Reggaeton tourism",
          "Cumbia tourism",
          "Merengue tourism",
          "Urban tourism",
          "Shopping tourism",
        ],
        offers: {
          "@type": "Offer",
          name: "Tour proposed by LatinSalsa for tourists interested in dancing or adventures.",
          description: `The price includes hotel stays, breakfast, dance classes and much more. Plane tickets not included.`,
          price: `${price}.00`,
          priceCurrency: "EUR",
          availabilityEnds: `${priceValid}`,
          url: `${siteUrl}/booking`,
          eligibleRegion: [
            "DE",
            "FR",
            "ES",
            "AT",
            "NL",
            "LU",
            "CR",
            "PL",
            "IT",
            "BE",
          ],
          offeredBy: {
            "@type": "Organization",
            name: `${legalName}`,
            url: `${lsDe}`,
          },
        },
      }}
    </JsonLd>
  )
}

const query = graphql`
  query TourJson {
    site {
      siteMetadata {
        duration
        price
        description
        priceValid
        siteUrl
        legalName
        lsDe
      }
    }
  }
`
