import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import AboutText from "./aboutText"

export default function AboutPageIndex() {
  return (
    <Container>
      <Row>
        <div className="col-sm-4 offset-sm-4">
          <AboutText />
        </div>
        <div className="col-sm-4">
          <StaticImage
            src="../../images/natalia-pacheco-thumb.jpg"
            alt="Natalia Pacheco"
            className="img-thumbnail"
            height={250}
          />
        </div>
      </Row>
    </Container>
  )
}
